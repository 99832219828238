export const PREFIX = 'demo/';
export const SUMMARIZE_POST_REQUESTED = `${PREFIX}SUMMARIZE_POST_REQUESTED`;
export const SUMMARIZE_POST_REQUEST_SUCCEEDED = `${PREFIX}SUMMARIZE_POST_REQUEST_SUCCEEDED`;
export const SUMMARIZE_POST_REQUEST_FAILED = `${PREFIX}SUMMARIZE_POST_REQUEST_FAILED`;
export const RANDOM_IMAGE_REQUESTED = `${PREFIX}RANDOM_IMAGE_REQUESTED`;
export const RANDOM_IMAGE_REQUEST_SUCCEEDED = `${PREFIX}RANDOM_IMAGE_REQUEST_SUCCEEDED`;
export const RANDOM_IMAGE_REQUEST_FAILED = `${PREFIX}RANDOM_IMAGE_REQUEST_FAILED`;

export function summarizePostURLRequested(url) {
    return {
        type: SUMMARIZE_POST_REQUESTED,
        payload: { url },
    };
}

export function summarizePostTextRequested(title, text) {
    return {
        type: SUMMARIZE_POST_REQUESTED,
        payload: { title, text },
    };
}

export function summarizePostRequestSucceeded(body) {
    return {
        type: SUMMARIZE_POST_REQUEST_SUCCEEDED,
        payload: { body },
    };
}

export function summarizePostRequestFailed(errors) {
    return {
        type: SUMMARIZE_POST_REQUEST_FAILED,
        payload: { errors },
    };
}

export function randomImageRequested() {
    return {
        type: RANDOM_IMAGE_REQUESTED,
    };
}

export function randomImageRequestSucceeded(url) {
    return {
        type: RANDOM_IMAGE_REQUEST_SUCCEEDED,
        payload: { url },
    };
}

export function randomImageRequestFailed(errors) {
    return {
        type: RANDOM_IMAGE_REQUEST_FAILED,
        payload: { errors },
    };
}
