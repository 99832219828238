import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';
import useStyles from './styles';
import theme from '../../../theme';

const FormInput = ({
    errorTextColor,
    noLeftMargin,
    Icon,
    onIconClick,
    as,
    ...props
}) => {
    const classes = useStyles({ errorTextColor, noLeftMargin });
    const inputClasses = {
        root: classes.root,
        input: classes.input,
    };

    return (
        <>
            <div className={classes.container}>
                <Field as={as} classes={inputClasses} {...props} />
                {Icon && (
                    <Icon className={classes.icon} onClick={onIconClick} />
                )}
            </div>
            <ErrorMessage
                name={props.name}
                className={classes.errorBelow}
                component="span"
            />
        </>
    );
};

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    errorTextColor: PropTypes.string,
    noLeftMargin: PropTypes.bool,

    // an instance of a material UI icon, which is just an object
    Icon: PropTypes.object,
    onIconClick: PropTypes.func,
    as: PropTypes.elementType,
};

FormInput.defaultProps = {
    errorTextColor: theme.palette.error.main,
    noLeftMargin: false,
    Icon: null,
    onIconClick: null,
    as: Input,
};

export default FormInput;
