import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import {
    Grid,
    withStyles,
    Typography,
    CircularProgress,
    MenuItem,
    Select,
} from '@material-ui/core';
import * as Yup from 'yup';
import Divider from '../../common/Divider';
import Form from '../../common/Form';
import FormInput from '../../common/Form/FormInput';
import theme from '../../theme';
import styles from './styles';
import { connect } from 'react-redux';
import {
    summarizePostURLRequested,
    summarizePostTextRequested,
} from '../../generateContent/actions';
import {
    getSummaryErrors,
    getSummarySentences,
    isSummaryLoading,
} from '../../generateContent/selectors';

const initialValues = {
    url: '',
    text: '',
};

const colorOptions = [
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'accent', label: 'Accent' },
];
const defaultPrimary = { r: 92, g: 165, b: 189, a: 1 };
const defaultSecondary = { r: 58, g: 58, b: 58, a: 1 };
const defaultAccent = { r: 176, g: 218, b: 150, a: 1 };

const validationSchema = Yup.object().shape({
    url: Yup.string().url('Enter a valid URL or leave blank.'),
    text: Yup.string().min(4, 'You must enter at least a 4-letter word.'),
});

const GenerateContent = ({
    classes,
    isLoading,
    onSummarizeURLRequested,
    onSummarizeTextRequested,
     onEditLayerRequested,
    setSelectedAnimatedData,
    setSelectedLayer,
     selectedLayer,
    summarySentences,
    errors,
}) => {
    const [picked, setPicked] = useState('primary');
    const [sketch, setSketch] = useState(defaultPrimary);
    const [colors, setColors] = useState({
        primary: defaultPrimary,
        secondary: defaultSecondary,
        accent: defaultAccent,
    });

    const colorSample = {
        width: '36px',
        height: '36px',
        borderColor: 'black',
        borderRadius: '2px',
        background: `rgba(
            ${sketch.r},
            ${sketch.g},
            ${sketch.b},
            ${sketch.a}
        )`,
    };

    const handleColorSelectionChange = (event) => {
        colors[picked] = sketch;
        setColors(colors);
        setPicked(event.target.value);
        setSketch(colors[event.target.value]);
    };

    const handleColorChangeComplete = (color) => {
        colors[picked] = color.rgb;
        setColors(colors);
    };

    const handleColorChange = (color) => {
        setSketch(color.rgb);
    };

    const submitHandler = (values) => {
        const newErrors = [];
        if (!values.url && !values.text) {
            newErrors.push('You must provide one of the above.');
        } else {
            if (values.url) {
                onSummarizeURLRequested(values.url);
            } else if (values.title && values.text) {
                onSummarizeTextRequested(values.title, values.text);
            }
        }
    };

    return (
        <Grid container className={classes.container} justify="center">
            <Grid
                container
                item
                className={classes.wrapper}
                direction="column"
                alignItems="center"
                sm={8}
                md={6}
            >
                <Typography variant="h1" className={classes.title}>
                    V<strong style={{fontWeight: '800'}}>ai</strong>der Content Generator
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    Maintaining an online presence has never been easier! Vaider automatically generates highly engaging social media images using natural language processing algorithms. Given company branding guidelines and blog posts, Vaider will create an eye catching image with relevant and meaningful text.                </Typography>
                <Grid container item direction="column" alignItems="center">
                    <Typography variant="body1" className={classes.text}>
                        Specify some colors for your content.
                    </Typography>
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        className={classes.colors}
                    >
                        <Grid container item alignItems="center" sm={4}>
                            <Grid item sm={6}>
                                <Select
                                    value={picked}
                                    onChange={handleColorSelectionChange}
                                >
                                    {colorOptions.map(option => (
                                        <MenuItem value={option.value} key={`${option.value}-color`}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item sm={6}>
                                <div className={classes.swatch}>
                                    <div style={colorSample} />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <ChromePicker
                                color={sketch}
                                onChange={handleColorChange}
                                onChangeComplete={handleColorChangeComplete}
                            />
                        </Grid>
                    </Grid>
                    <Divider text="PLUS" lineColor="black" textColor="black" />
                    <Typography variant="body1" className={classes.text}>
                        Enter a link to an article and/or some text to seed
                        content generation.
                    </Typography>
                    <Divider className={classes.divider} />
                    <Form
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submitHandler}
                        submitBtnText={
                            isLoading ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                'Generate'
                            )
                        }
                        submitBtnColor={theme.palette.primary.main}
                        submitBtnTextColor={theme.palette.primary.contrastText}
                        isLoading={isLoading}
                    >
                        <FormInput name="url" placeholder="Enter a URL" />
                        <FormInput
                            name="title"
                            placeholder="Enter the article title"
                        />
                        <FormInput
                            name="text"
                            placeholder="Enter the article text"
                        />
                    </Form>
                </Grid>
                <Grid container item direction="column" alignItems="center">
                    {errors?.map((error, id) => (
                        <Typography
                            key={`error-${id}`}
                            variant="body2"
                            className={classes.error}
                        >
                            {typeof error === 'string' ? error : error.message}
                        </Typography>
                    ))}
                </Grid>
                <Grid container item direction="column">
                    {summarySentences?.map((sentence) => (
                        <Typography key={sentence} variant="body2">
                            {sentence}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

const StyledGenerateContentScreen = withStyles(styles)(GenerateContent);

const mapStateToProps = (state) => ({
    isLoading: isSummaryLoading(state),
    summarySentences: getSummarySentences(state),
    errors: getSummaryErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSummarizeURLRequested: (url) => dispatch(summarizePostURLRequested(url)),
    onSummarizeTextRequested: (title, text) =>
        dispatch(summarizePostTextRequested(title, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledGenerateContentScreen);
