import { makeStyles } from '@material-ui/core';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        const themeType = props.color === 'secondary' ? 'secondary' : 'primary';
        const colorTheme = theme.palette[themeType].main;
        const darkColorTheme = theme.palette[themeType].dark;
        const activeBgColor = fade(props.btnColor || colorTheme, 0.25);

        if (props.variant === 'text') {
            const color = props.textColor || colorTheme;
            const hoverBgColor = fade(props.btnColor || colorTheme, 0.1);

            return {
                color,

                '&:hover': {
                    backgroundColor: hoverBgColor,
                },

                '&:active': {
                    backgroundColor: activeBgColor,
                },

                '&:disabled': {
                    color,
                    opacity: 0.65,
                },
            };
        }
        if (props.variant === 'contained') {
            const color = props.textColor || theme.palette.primary.contrastText;
            const backgroundColor = props.btnColor || colorTheme;
            const hoverBgColor = props.btnColor
                ? darken(props.btnColor, 0.1)
                : darkColorTheme || darken(colorTheme, 0.1);

            return {
                color,
                backgroundColor,

                '&:hover': {
                    backgroundColor: hoverBgColor,
                },

                '&:active': {
                    backgroundColor: activeBgColor,
                },

                '&:disabled': {
                    color,
                    backgroundColor,
                    opacity: 0.65,
                },
            };
        }
        if (props.variant === 'outlined') {
            const color = props.textColor || colorTheme;
            const borderColor = props.btnColor || colorTheme;
            const hoverBgColor = fade(props.btnColor || colorTheme, 0.1);

            return {
                color,
                borderColor,

                '&:hover': {
                    backgroundColor: hoverBgColor,
                    borderColor,
                },

                '&:active': {
                    backgroundColor: activeBgColor,
                },

                '&:disabled': {
                    color,
                    borderColor,
                    opacity: 0.65,
                },
            };
        }
        return {};
    },
}));

export default useStyles;
