export const PREFIX = 'editor/';


export const LAYER_UPDATE_REQUESTED = `${PREFIX}LAYER_UPDATE_REQUESTED`;
export const LAYER_UPDATE_REQUEST_SUCCEEDED = `${PREFIX}LAYER_UPDATE_REQUEST_SUCCEEDED`;
export const LAYER_UPDATE_REQUEST_FAILED = `${PREFIX}LAYER_UPDATE_REQUEST_FAILED`;


export const LAYER_SELECTED = `${PREFIX}NEW_LAYER_SELECTED`;
export const ANIMATION_SELECTED = `${PREFIX}NEW_ANIMATION_SELECTED`;


export function animationSelected(animationData) {
    return {
        type: ANIMATION_SELECTED,
        payload: { animationData },
    };
}

export function layerSelected(layer) {
    return {
        type: LAYER_SELECTED,
        payload: { layer },
    };
}


export function layerUpdateRequested(updatedLayer) {
    return {
        type: LAYER_UPDATE_REQUESTED,
        payload: { updatedLayer },
    };
}

export function layerUpdateSucceeded(layer) {
    return {
        type: LAYER_UPDATE_REQUEST_SUCCEEDED,
        payload: { layer },
    };
}


export function layerUpdateFailed(errors) {
    return {
        type: LAYER_UPDATE_REQUEST_FAILED,
        payload: { errors },
    };
}
