import { all, takeLatest, call, put } from 'redux-saga/effects';
import {
    RANDOM_PALETTE_REQUESTED,
    randomPaletteRequestFailed,
    randomPaletteRequestSucceeded,
} from './actions';
import { fetchRandomPalette } from './api';

export function* handleRandomPaletteRequest() {
    try {
        const palette = yield call(fetchRandomPalette);
        yield put(randomPaletteRequestSucceeded(palette));
    } catch (error) {
        yield put(randomPaletteRequestFailed(error));
    }
}

export default function* demoSaga() {
    yield all([
        takeLatest(RANDOM_PALETTE_REQUESTED, handleRandomPaletteRequest),
    ]);
}
