import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import BUTTON_COLORS from '../constants';
import useStyles from './styles';

const RawButton = ({
    children,
    variant,
    color,
    btnColor,
    textColor,
    ...props
}) => {
    const defaultColor = color === 'secondary' ? 'secondary' : 'default';
    const classes = useStyles({ variant, color, btnColor, textColor });

    return (
        <Button
            classes={classes}
            variant={variant}
            color={defaultColor}
            {...props}
        >
            {children}
        </Button>
    );
};

RawButton.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.string,
    color: PropTypes.oneOf(BUTTON_COLORS),
    btnColor: PropTypes.string,
    textColor: PropTypes.string,
};

RawButton.defaultProps = {
    children: PropTypes.node,
    variant: 'contained',
    color: 'default',
    btnColor: null,
    textColor: null,
};

export default RawButton;
