import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '10px 0',
        fontFamily: `'proxima-nova', sans-serif`,
    },
    line: (props) => ({
        height: `${props.lineWidth}px`,
        width: '100%',
        backgroundColor: props.lineColor,
        opacity: 0.8,
    }),
    text: (props) => ({
        margin: props.text && '0 15px',
        fontSize: `${props.fontSize}px`,
        color: props.textColor,
        opacity: 0.8,
    }),
}));

export default useStyles;
