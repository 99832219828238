const styles = (theme) => {
    return {
        container: {
            paddingTop: '50px',
            height: '100vh',
            backgroundImage: theme.palette.common.white,
            color: theme.palette.common.black,
        },
        wrapper: {
            padding: '0 5px',
        },
        logo: {
            margin: '0 auto 28px',
            maxWidth: '50%',
        },
        link: {
            color: theme.palette.common.white,
            marginBottom: '10px',
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline',
                color: theme.palette.common.black,
                backgroundColor: 'transparent',
            },
        },
        title: {
            margin: '60px 0 20px',
            fontSize: '30px',
        },
        button: {
            margin: '10px 0 15px',
            textDecoration: 'none',
        },
        text: {
            textAlign: 'center',
            marginBottom: '15px',
        },
        error: {
            color: theme.palette.error.main,
            textAlign: 'center',
        },
        colors: {
            margin: '20px 0 40px 0',
        },
        swatch: {
            display: 'inline-block',
            margin: '4px 0 10px 15px',
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            cursor: 'pointer',
        },
        buttonContent: {
            display: 'flex',
        },
        buttonIcon: {
            maxHeight: '22.5px',
            marginRight: '12px',
        },
        loader: {
            maxWidth: '80px',
            height: 'auto',
            padding: '0px',
            backgroundColor: theme.palette.common.black,
            border: '2px',
            borderRadius: '5px',
        },
        divider: {
            marginBottom: '10px',
        },
        footer: {
            backgroundColor: 'transparent',
        },
    };
};

export default styles;
