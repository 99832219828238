/**
 * Generate width styles for a table column.
 *
 * Setting to `'auto'` essentially directs the browser that this cell should fill the remaining space
 * available to it. Every table should have one cell (preferably) that is "auto" while the rest are
 * fixed width. This helps prevent jumpiness when loading the next page, etc.
 *
 * @param {'auto'|number} width
 * @param otherStyles
 * @return {{width: string|number, maxWidth?: number}}
 */
export const generateTableColumnStyles = (width, otherStyles = {}) => ({
    ...(width === 'auto' ? { width } : { width, maxWidth: width }),
    ...otherStyles,
});

/**
 * Generate styles that can be applied to an img's styles to make it perfectly round.
 *
 * @param {number} size
 * @return {{borderRadius: number, maxHeight: number, width: number, maxWidth: number, height: number}}
 */
export const generateRoundImageStyles = (size) => ({
    width: size,
    maxWidth: size,
    height: size,
    maxHeight: size,
    borderRadius: Math.ceil(size / 2),
});
