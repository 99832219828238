import Lottie from 'lottie-web';

export const LAYER_IMAGE = 'image';
export const LAYER_NULL = 'null';
export const LAYER_SHAPE = 'shape';
export const LAYER_TEXT = 'text';
export const LAYER_SOLID = 'solid';

export const LAYER_TYPES = {
    1: LAYER_SOLID,
    2: LAYER_IMAGE,
    3: LAYER_NULL,
    4: LAYER_SHAPE,
    5: LAYER_TEXT,
};

/**
 * Class with accessors to the underlying lottie api so that lottie files can be modified dynamically
 */
export default class LottieAnimation {
    LAYER_TYPES = LAYER_TYPES;

    lottieData = null;

    container = null;

    constructor(lottieData, container) {
        this.lottieData = lottieData;
        this.container = container;
        this.options = {
            renderer: 'svg',
            loop: false,
            autoplay: false,
            segments: false,
            animationData: this.lottieData,
        };
        this.setupLottie();
    }

    updateAnimationData(lottieData){
        this.lottieData = lottieData;
        this.options = {
            renderer: 'svg',
            loop: false,
            autoplay: false,
            segments: false,
            animationData: this.lottieData,
        };
        this.setupLottie();
    }


    /**
     * Call when the container that will contain the lottie changes.
     * @param container Dom element (ie div) which will hold the lottie
     */
    updateContainer = (container) => {
        if (this.container !== null || container === null) {
            return;
        }
        this.container = container;
        this.setupLottie();
    };

    /**
     * Sets up the underlying Lottie-web and begins the animation
     */
    setupLottie() {
        if (this.anim && this.container?.childElementCount > 0) {
            this.container.innerHTML = '';
        }
        this.options = {
            container: this.container,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            segments: false,
            animationData: this.lottieData,
        };
        this.anim = Lottie.loadAnimation(this.options);
        this.anim.play();
    }

    layerNames() {
        const { lottieData } = this;
        const layers = lottieData?.layers.map(
            ({ nm: layerName = '' }) => layerName,
        );
        return layers;
    }

    /**
     * Returns lottie layer with some additional information such as name and human readable type
     * @returns {*}
     */
    getLayers() {
        return this.lottieData?.layers.map(
            ({ nm: layerName = '', ty, ...layerData }) => {
                return {
                    name: layerName,
                    type: LAYER_TYPES[ty],
                    id: layerData?.ind || layerData?.refId,
                    ...layerData,
                };
            },
        );
    }

    /**
     *
     * @param layer the object containing the layer that is going to be modified
     * @param newText The replacement text for the current layer
     * @returns lottieData
     */
    updateTextLayer({ ind: index }, newText) {
        const { lottieData } = this;
        const layer = lottieData.layers[index];
        if (layer) {
            const data = lottieData.layers[index - 1].t.d.k[0].s;
            lottieData.layers[index - 1].t.d.k[0].s.t = newText;
            this.anim.renderer.elements[index - 1].updateDocumentData({
                ...data,
                t: newText,
            });
        }
        return this.lottieData;
    }

    /**
     *
     * @param layer the object containing the layer that is going to be modified
     * @param newText The replacement text for the current layer
     * @returns lottieData
     */
    getLayerText({ ind: index }) {
        const { lottieData } = this;
        const layer = lottieData.layers[index - 1];
        if (layer) {
            return lottieData.layers[index - 1]?.t?.d?.k[0]?.s?.t
        }
    }

    /**
     *
     * @param index
     * @param newColor must be in [R,G,B] format
     * @returns {null}
     */
    updateTextLayerColor({ ind: index }, newColor) {
        const { lottieData } = this;
        const layer = lottieData.layers[index];
        if (layer) {
            const data = lottieData.layers[index - 1].t.d.k[0].s;
            lottieData.layers[index - 1].t.d.k[0].s.fc = newColor;
            this.anim.renderer.elements[index - 1].updateDocumentData({
                ...data,
                fc: newColor,
            });
        }
        return this.lottieData;
    }

    /**
     * Updates assets in the animation to make use of a URL instead of a local asset
     * @param layer
     * @param newAssetUrl
     * @returns {null}
     */
    updateAssetLayer({ refId: id }, newAssetUrl) {
        this.lottieData.assets = this.lottieData?.assets.map((asset) => {
            if (asset.id === id) {
                delete asset.u;
                asset.p = newAssetUrl;
            }
            return asset;
        });
        return this.lottieData;
    }
}
