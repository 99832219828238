import {
    RANDOM_IMAGE_REQUEST_FAILED,
    RANDOM_IMAGE_REQUEST_SUCCEEDED,
    RANDOM_IMAGE_REQUESTED,
    SUMMARIZE_POST_REQUEST_FAILED,
    SUMMARIZE_POST_REQUEST_SUCCEEDED,
    SUMMARIZE_POST_REQUESTED,
} from './actions';

const generateContentInitialState = {
    sentences: [],
    loading: false,
    errors: null,
};

export default function generateContentReducer(
    state = generateContentInitialState,
    action,
) {
    const { type, payload = {} } = action;
    if (type === SUMMARIZE_POST_REQUESTED || type === RANDOM_IMAGE_REQUESTED) {
        return {
            ...state,
            loading: true,
        };
    } else if (type === SUMMARIZE_POST_REQUEST_SUCCEEDED) {
        const {
            body: { sentences },
        } = payload;
        return {
            ...state,
            loading: false,
            sentences: sentences,
            errors: null,
        };
    } else if (type === SUMMARIZE_POST_REQUEST_FAILED || type === RANDOM_IMAGE_REQUEST_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            loading: false,
            errors,
        };
    } else if (type === RANDOM_IMAGE_REQUEST_SUCCEEDED) {
        const { url } = payload;
        return {
            ...state,
            loading: false,
            randomImage: url,
        };
    }
    return state;
}
