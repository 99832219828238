import { createSelector } from 'reselect';

export const getEditorState = (state) => state.editor;

export const getSelectedAnimation = createSelector(
    getEditorState,
    (editor) => editor.animationData,
);

export const getSelectedAnimationData = createSelector(
    getSelectedAnimation,
    (animation) => animation.data,
);


export const getSelectedLayer = createSelector(
    getEditorState,
    (editor) => editor.selectedLayer,
);


export const getEditorLoading = createSelector(
    getEditorState,
    (editor) => !!editor.loading,
);

export const getEditorTemplates = createSelector(
    getEditorState,
    (editor) => editor.templates,
);

