import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Button from '../Button';
import styles from './styles';

const ShineForm = ({
    children,
    classes,
    initialValues,
    validator,
    validationSchema,
    onSubmit,
    submitBtnType,
    submitBtnColor,
    submitBtnTextColor,
    submitBtnText,
    isLoading,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validate={validator}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isValid, ...formikProps }) => (
                <Form className={classes.form}>
                    <Grid container direction="column">
                        {typeof children === 'function'
                            ? children(formikProps)
                            : children}
                        <Button
                            color={submitBtnType}
                            btnColor={submitBtnColor}
                            textColor={submitBtnTextColor}
                            size="large"
                            type="submit"
                            disabled={!isValid || isLoading}
                        >
                            {submitBtnText}
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

ShineForm.propTypes = {
    children: PropTypes.oneOfType([
        // Specify Formik field(s)
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,

        // Can supply a function as the form children to get access to Formik props
        PropTypes.func,
    ]),
    classes: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    validator: PropTypes.func,
    validationSchema: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitBtnType: PropTypes.string,
    submitBtnColor: PropTypes.string,
    submitBtnTextColor: PropTypes.string,
    submitBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isLoading: PropTypes.bool,
};

ShineForm.defaultProps = {
    children: null,
    validator: null,
    validationSchema: null,
    submitBtnType: 'accent',
    submitBtnColor: null,
    submitBtnTextColor: null,
    submitBtnText: 'submit',
    isLoading: false,
};

export default withStyles(styles)(ShineForm);
