import { all, takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
    SUMMARIZE_POST_REQUESTED,
    summarizePostRequestFailed,
    summarizePostRequestSucceeded,
    RANDOM_IMAGE_REQUESTED,
    randomImageRequestFailed,
    randomImageRequestSucceeded,
} from './actions';
import {
    summarizePostFromText,
    summarizePostFromURL,
    getRandomImage,
} from './api';

export function* handleSummarizePost({ payload }) {
    try {
        let response = null;
        if (payload.url) {
            response = yield call(summarizePostFromURL, payload.url);
        } else if (payload.title && payload.text) {
            response = yield call(
                summarizePostFromText,
                payload.title,
                payload.text,
            );
        }
        yield put(summarizePostRequestSucceeded(response));
        yield put(push('/content'))
    } catch (error) {
        yield put(summarizePostRequestFailed(error));
    }
}

export function* getRandomImageUrl() {
    try {
        const response = yield call(getRandomImage);
        yield put(randomImageRequestSucceeded(response.url));
    } catch (error) {
        yield put(randomImageRequestFailed(error));
    }
}

export default function* summarizePostSaga() {
    yield all([
        takeLatest(SUMMARIZE_POST_REQUESTED, handleSummarizePost),
        takeLatest(RANDOM_IMAGE_REQUESTED, getRandomImageUrl),
    ]);
}
