import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import theme from '../../theme';
import useStyles from './styles';

const Divider = ({
    text,
    textColor,
    fontSize,
    lineColor,
    lineWidth,
    ...props
}) => {
    const classes = useStyles({
        text,
        textColor,
        fontSize,
        lineColor,
        lineWidth,
    });

    return (
        <Grid
            container
            item
            className={classes.container}
            alignItems="center"
            direction="row"
            wrap="nowrap"
            {...props}
        >
            <Grid item className={classes.line} />
            <Grid item className={classes.text}>
                {text}
            </Grid>
            <Grid item className={classes.line} />
        </Grid>
    );
};

Divider.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,
    lineColor: PropTypes.string,
    lineWidth: PropTypes.number,
};

Divider.defaultProps = {
    text: null,
    textColor: theme.palette.common.white,
    fontSize: 14,
    lineColor: theme.palette.common.white,
    lineWidth: 1,
};

export default Divider;
