import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import BUTTON_COLORS from './constants';
import theme from '../../theme';
import {
    defaultTheme,
    accentTheme,
    dangerTheme,
    warningTheme,
    successTheme,
    infoTheme,
    whiteTheme,
    grayTheme,
    lightTheme,
} from './styles';
import RawButton from './RawButton';

const themes = {
    default: defaultTheme,
    accent: accentTheme,
    danger: dangerTheme,
    warning: warningTheme,
    success: successTheme,
    info: infoTheme,
    white: whiteTheme,
    gray: grayTheme,
    light: lightTheme,
};

const AdminButton = ({ children, color, ...props }) => {
    return (
        <ThemeProvider theme={themes[color] || theme}>
            <RawButton color={color} {...props}>
                {children}
            </RawButton>
        </ThemeProvider>
    );
};

AdminButton.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(BUTTON_COLORS),
};

AdminButton.defaultProps = {
    children: PropTypes.node,
    color: 'default',
};

export default AdminButton;
