import { put, call } from 'redux-saga/effects';
import { initializationFailed, initializationSucceeded } from './actions';
import { handleRandomPaletteRequest } from '../demo/saga';

export default function* initSaga() {
    // TODO Remove this dummy code used to demo how init works and actually use it to preload, do auth check, etc.
    if (window.location.hash && window.location.hash.match(/^#?error$/i)) {
        yield put(
            initializationFailed(
                'This is a fake failure since the URL contains "#error".',
            ),
        );
    } else {
        // Preload a random palette for the UI
        yield call(handleRandomPaletteRequest);
        yield put(initializationSucceeded());
    }
}
