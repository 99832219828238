/**
 * Fetch a random AI-generated color palette from the ColorMind API.
 *
 * @returns {Promise<([number, number, number])[]>}
 */
export async function fetchRandomPalette() {
    const resp = await fetch('http://colormind.io/api/', {
        method: 'POST',
        body: '{"model":"default"}',
    });
    if (resp.status >= 400) {
        throw new Error(
            'An error occurred while communicating with the ColorMind API. Try again later.',
        );
    }
    const respBody = await resp.json();
    if (
        !respBody ||
        !respBody.result ||
        !Array.isArray(respBody.result) ||
        respBody.result.length === 0
    ) {
        throw new Error(
            'The ColorMind API returned an unexpected response. Try again later.',
        );
    }
    return respBody.result;
}
