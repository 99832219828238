import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import GenerateContent from './screens/GenerateContent';
import ContentEditor from './screens/ContentEditor';

function Router({ history }) {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/generate" component={GenerateContent} />
                <Route path="/content" component={ContentEditor} />
                <Redirect to="/generate" />
            </Switch>
        </ConnectedRouter>
    );
}

Router.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Router;
