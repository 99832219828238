import { all, call } from 'redux-saga/effects';
import initSaga from '../init/saga';
import demoSaga from '../demo/saga';
import summarizePostSaga from '../generateContent/saga';
import editorSaga from '../common/Lottie/Editor/saga';

export default function* rootSaga() {
    yield all([
        call(initSaga),
        call(demoSaga),
        call(summarizePostSaga),
        call(editorSaga)
    ]);
}
