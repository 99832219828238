import {
    RANDOM_PALETTE_REQUEST_FAILED,
    RANDOM_PALETTE_REQUEST_SUCCEEDED,
    RANDOM_PALETTE_REQUESTED,
} from './actions';

const demoInitialState = {
    randomPalette: {
        palette: null,
        loading: false,
        errors: null,
    },
};

export default function demoReducer(state = demoInitialState, action) {
    const { type, payload = {} } = action;

    if (type === RANDOM_PALETTE_REQUESTED) {
        return {
            ...state,
            randomPalette: {
                ...state.randomPalette,
                loading: true,
            },
        };
    } else if (type === RANDOM_PALETTE_REQUEST_SUCCEEDED) {
        const { palette } = payload;
        return {
            ...state,
            randomPalette: {
                ...state.randomPalette,
                loading: false,
                palette,
                errors: null,
            },
        };
    } else if (type === RANDOM_PALETTE_REQUEST_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            randomPalette: {
                ...state.randomPalette,
                loading: false,
                errors,
            },
        };
    }

    return state;
}
