import { createMuiTheme } from '@material-ui/core/styles';
import { generateTableColumnStyles } from '../utils/styles';

const white = '#ffffff';
const black = 'rgba(0, 0, 0, 0.9)';

// Todo: update palette with shine colors.
const palette = {
    white,
    black,
    background: white,
    foreground: black,
    primary: '#6600bf',
    primaryDark: '#32005e',
    primaryForeground: `#333`,
    secondary: '#0a081e',
    accent: '#a70009',
    danger: '#f44336',
    dangerForeground: white,
    success: '#4caf50',
    successForeground: black,
    warn: '#ff9800',
    warnForeground: black,
    info: '#2196f3',
    infoForeground: white,
    disabled: 'rgba(0, 0, 0, 0.38)',
    shadow: 'rgba(0,0,0,0.75)',
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: palette.primary,
            dark: palette.primaryDark,
            contrastText: '#FFF',
        },
        secondary: {
            main: palette.secondary,
            contrastText: black,
        },
        accent: {
            main: palette.accent,
            contrastText: white,
        },
        error: {
            main: palette.danger,
        },
    },
    typography: {
        fontFamily: '"proxima-nova", sans-serif',
        useNextVariants: true,
        subtitle1: {
            fontWeight: 100,
            textTransform: 'uppercase',
            letterSpacing: 4,
            fontSize: 28,
        },
    },
    common: {
        boxShadow: '0 0 2px 2px black',
    },
    // Custom variables
    custom: {
        headerPadding: 6,
        containerPadding: 30,
        exteriorContainerPadding: 80,
        containerMaxWidth: 1460,
        darkBlue: '#022B6E',
        successBlue: '#5C8CD9',
        red: '#8b3f5',
        editGreen: '#00C14A',
        buttonHoverOpacity: 0.75,
        paginationFontSize: 12,
        grey: '#EEEEEE',
        actionsAndFiltersWidth: 300,
        actionsAndFiltersWidthForMediumAndDown: 230,
    },
    // Custom component styling
    shineAdmin: {
        select: {
            largeHeight: 54,
            largeMinWidth: 125,
            compactHeight: 25,
            compactMinWidth: 50,
        },
        dashboardPanel: {
            minWidth: 550,
        },
        // Common head cell styling for columns that show up in multiple tables
        tableHeadCells: {
            checkbox: generateTableColumnStyles(70),
            id: generateTableColumnStyles(50),
            name: generateTableColumnStyles(240),
            photo: generateTableColumnStyles(70),
            status: generateTableColumnStyles(120),
            // Note that "auto" essentially dictates this cell is the cell that will stretch to fill remaining space
            date: generateTableColumnStyles('auto'),
        },
    },
    // Global props for app-wide changes.
    props: {
        MuiButtonBase: {
            disableRipple: true, // We want to disable the button ripple, for the whole app.
        },
    },
    // Overrides for Material UI components
    overrides: {
        MuiPrivateTabScrollButton: {
            root: {
                width: 6,
                color: '#003283',
                marginLeft: 3,
                marginRight: 3,
            },
        },
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
        MuiFilledInput: {
            input: {
                marginBottom: 12,
            },
        },
        MuiDialogContentText: {
            root: {
                textAlign: 'center',
            },
        },
        MuiDialogTitle: {
            root: {
                textAlign: 'center',
            },
        },
        MuiDialogActions: {
            root: {
                paddingTop: 20,
            },
        },
        MuiTableCell: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
        DataTable: {
            root: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
            meta: {
                padding: '20px 0',
                margin: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                width: 320,
            },
            filters: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
            },
            searchContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
            search: {
                flex: 1,
            },
            searchInput: {
                'label + &': {
                    marginTop: 0,
                },
            },
            searchControl: {
                width: '100%',
            },
            tableBodyWithDataRow: {
                '&:nth-child(2n)': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
        },
        DataTableColumn: {
            sortArrow: {
                width: 'auto',
                height: '1em',
                marginTop: '-0.5em',
                marginBottom: '-0.5em',
            },
        },
    },
});

theme.overrides.DataTable = {
    ...theme.overrides.DataTable,
    checkboxHeadCell: theme.shineAdmin.tableHeadCells.checkbox,
};

theme.overrides.DataTableColumn = {
    ...theme.overrides.DataTableColumn,
    headCell: {
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    bodyCell: {
        color: theme.palette.primary.main,
    },
};

export default theme;
