import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';

export const getGenerateContentState = (state) => state.generateContent;

export const isSummaryLoading = createSelector(
    getGenerateContentState,
    (generateContent) => !!generateContent.loading,
);

export const getSummarySentences = createSelector(
    getGenerateContentState,
    (generateContent) => generateContent.sentences,
);

export const getSummaryErrors = createSelector(
    getGenerateContentState,
    (generateContent) => coerceIntoErrors(generateContent.errors),
);

export const getRandomImageUrl = createSelector(
    getGenerateContentState,
    (generateContent) => generateContent.randomImage,
);
