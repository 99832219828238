import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import TextFieldIcon from '@material-ui/icons/TextFields';
import GradeIcon from '@material-ui/icons/Grade';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Box, Button, TextField } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import LottieAnimation, {
    LAYER_IMAGE,
    LAYER_SHAPE,
    LAYER_TEXT,
} from '../LottieAnimation';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    editorControls: {
        margin: '-25px 0 25px',
    },
    textField: {
        marginTop: 20,
        width: '86%',
    },
    sketchGrid: {
        position: 'absolute',
        right: 25,
    },
    sketch: {
        marginTop: -36,
    },
}));

export default function LottieEditor({
                                         animationData: initData,
                                         selectedLayer,
                                         onLayerSelected,
                                         sentences,
                                         randomImage,
                                         onRandomImageRequested
                                     })
{
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [layerColor, setLayerColor] = useState({r: 0, g: 0, b: 0, a: 1});
    const [layerText, setLayerText] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const LottieApi = new LottieAnimation(initData, null);
    const loadLottieAnim = (element) => {
        LottieApi.updateContainer(element);
    };

    const [summarySentenceIndex, setSummarySentenceIndex] = useState(0)
    const [hasLoaded, setHasLoaded] = useState(false)


    const handleColorChange = (color) => {
        setLayerColor(color.rgb);
    };

    const confirmColor = () => {
        const {r, g, b} = layerColor;
        const newColor = [r, g, b].map(c => c / 255);
        setShowColorPicker(false);
        LottieApi.updateTextLayerColor(
            selectedLayer,
            newColor,
        );
    };

    const displayColorPicker = () => {
        setShowColorPicker(true);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLayerSelected = (layer) => {
        const textData = layer.t?.d?.k[0]?.s;
        if (textData && layer.type === LAYER_TEXT) {
            const { fc: layerColor } = textData;
            const sketchColor = layerColor.map(c => c * 255);
            setLayerColor({a: 1, r: sketchColor[0], g: sketchColor[1], b: sketchColor[2]});
            setLayerText(textData.t);
        }
        onLayerSelected(layer);
    };

    const getRandomImage = () => {
        onRandomImageRequested();
        layerUpdated(selectedLayer, randomImage);
    };

    const layerUpdated = (layer, newVal) => {
        if (!layer) {
            return;
        }
        onLayerSelected(layer);
        if (layer === LAYER_TEXT || layer.type === LAYER_TEXT) {
            LottieApi.updateTextLayer(layer, newVal);
        } else {
            LottieApi.updateAssetLayer(layer, newVal);
        }
    };
    let longestLayer = null
    let longestLayerLength = 0

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Editor
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                style={{ width: 0 }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {LottieApi.getLayers()?.map((layer, index) => {
                        if (!layer?.type) {
                            return;
                        }
                        let Icon;
                        let layerText;
                        switch (layer.type) {
                            case LAYER_IMAGE:
                                Icon = ImageIcon;
                                break;
                            case LAYER_TEXT:
                                Icon = TextFieldIcon;
                                layerText = LottieApi.getLayerText(layer);
                                if (layerText?.length > longestLayerLength) {
                                    longestLayer = layer
                                    longestLayerLength = layerText.length;
                                }
                                break;
                            case LAYER_SHAPE:
                                Icon = GradeIcon;
                                break;
                            default:
                                Icon = ErrorOutline;
                        }
                        if (sentences[summarySentenceIndex] && !hasLoaded && index === LottieApi.getLayers().length - 1 && longestLayer) {
                            setTimeout(() => {
                                LottieApi.updateTextLayer(longestLayer, sentences[summarySentenceIndex])
                                setHasLoaded(true)
                            }, 1000)
                        }
                        return (
                            <ListItem
                                button
                                key={layer.id}
                                onClick={() => handleLayerSelected(layer)}
                            >
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText primary={layer.name}>
                                    {layer.name}
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Box>
                    <Grid container direction="column" className={classes.editorControls}>
                        {selectedLayer && (
                            <>
                                <Grid container item direction="row" justify="center" alignItems="center">
                                    <Grid item md={8}>
                                        <strong>Layer {selectedLayer.id}: </strong>{selectedLayer.name}
                                    </Grid>
                                    <Grid item>
                                        {selectedLayer.type === LAYER_TEXT ? (
                                            <>
                                                {showColorPicker && (
                                                    <>
                                                        <Button onClick={confirmColor}>
                                                            Confirm Color
                                                        </Button>

                                                        <Grid className={classes.sketchGrid}>
                                                            <ChromePicker
                                                                className={classes.sketch}
                                                                color={layerColor}
                                                                onChange={handleColorChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {!showColorPicker && (
                                                    <Button color="info" onClick={displayColorPicker}>
                                                        Pick Color
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                onClick={getRandomImage}
                                            >
                                                Random Image
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                                {selectedLayer.type === LAYER_TEXT && (
                                    <Grid container row item justify="center">
                                        <TextField
                                            className={classes.textField}
                                            helperText="Update text"
                                            variant="outlined"
                                            value={layerText}
                                            onChange={(event) => {
                                                const newVal = event.target.value;
                                                layerUpdated(selectedLayer, newVal);
                                                setLayerText(newVal);
                                            }}
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                    <div
                        style={{ width: 600, height: 600 }}
                        ref={loadLottieAnim}
                    />
                </Box>
            </main>
            <Button onClick={() => {
                const index = (summarySentenceIndex + 1) % 5
                if (sentences[summarySentenceIndex]) {
                    LottieApi.updateTextLayer(longestLayer, sentences[index])
                    setSummarySentenceIndex(index)
                }
            }}>
                Next quote
            </Button>
        </div>
    );
}
