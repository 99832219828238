/**
 * Fetch a random AI-generated color palette from the ColorMind API.
 *
 * @returns {Promise<([number, number, number])[]>}
 */

export async function summarizePostFromURL(url) {
    const aylienUrl = 'https://api.aylien.com/api/v1/summarize?url=' + url;
    return summarizePost(aylienUrl);
}

export async function summarizePostFromText(title, text) {
    const aylienUrl =
        'https://api.aylien.com/api/v1/summarize?title=' +
        title +
        '&text=' +
        text;
    return summarizePost(aylienUrl);
}

async function summarizePost(url) {
    const resp = await fetch(url, {
        withCredentials: true,
        mode: 'cors',
        headers: {
            'X-AYLIEN-TextAPI-Application-Key':
                '56af5698e92d247c32f3a0648b57bf80',
            'X-AYLIEN-TextAPI-Application-ID': '59268b4c',
        },
    });
    if (resp.status >= 400) {
        throw new Error(
            'An error occurred while communicating with the Aylien API. Try again later.',
        );
    }
    const respBody = await resp.json();
    if (
        !respBody ||
        !respBody.sentences ||
        !Array.isArray(respBody.sentences)
    ) {
        throw new Error(
            'The Aylien API returned an unexpected response. Try again later.',
        );
    }

    return respBody;
}

export async function getRandomImage() {
    const imageUrl = 'https://picsum.photos/1080/1080';
    const resp = await fetch(imageUrl);
    if (resp.status !== 200) {
        throw new Error('An error occurred getting a random image from PicSum. Try again later.',);
    }
    return resp;
}
