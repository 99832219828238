import {
    LAYER_UPDATE_REQUEST_FAILED,
    LAYER_UPDATE_REQUEST_SUCCEEDED,
    LAYER_UPDATE_REQUESTED,
    ANIMATION_SELECTED, LAYER_SELECTED
} from './actions'

import LottieAnimation from '../LottieAnimation'
import {carimus, halloween} from '../data'
export const LottieApi = new LottieAnimation(null, null);

export const availableTemplates = [
    { name: 'Carimus Quote', data: carimus, id: 0 },
    { name: 'Scarimus', data: halloween, id: 2 },
];

const editorInitialState = {
    selectedLayer: null,
    loading: false,
    errors: null,
    animationData: availableTemplates[0],
    templates: availableTemplates
};

export default function editorReducer(
    state = editorInitialState,
    action,
) {
    const { type, payload = {} } = action;
    if (type === ANIMATION_SELECTED) {
        return {
            ...state,
            animationData: payload.animationData,
        };
    } else  if (type === LAYER_SELECTED) {
        return {
            ...state,
            selectedLayer: payload.layer,
        };
    }

    return state;
}
