import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import initReducer from '../init/reducer';
import demoReducer from '../demo/reducer';
import generateContentReducer from '../generateContent/reducer';
import editorReduced from '../common/Lottie/Editor/reducer';

export default function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        init: initReducer,
        demo: demoReducer,
        generateContent: generateContentReducer,
        editor: editorReduced
    });
}
