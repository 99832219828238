import { all, takeLatest, select } from 'redux-saga/effects';
import {
    ANIMATION_SELECTED, LAYER_UPDATE_REQUESTED
} from './actions'
import {LottieApi} from './reducer'
import {LAYER_TEXT} from '../LottieAnimation'
import {getSelectedAnimation, getSelectedLayer} from './selectors'

export function* handleNewAnimationSelected({ payload }) {
    LottieApi.updateAnimationData(payload.animationData.data)
}

export function* onUpdateLayer({payload}) {
    // console.log('layer updated')
    // console.log(payload)
    const layer = yield select(getSelectedLayer)
    if (layer.type === LAYER_TEXT) {
        // LottieApi.updateTextLayer(layer, payload.newLayer);
    } else {
        LottieApi.updateAssetLayer(layer, payload.newLayer);
    }
    // yield call(had)
}
export default function* summarizePostSaga() {
    yield all([
        takeLatest(ANIMATION_SELECTED, handleNewAnimationSelected),
        takeLatest(LAYER_UPDATE_REQUESTED, onUpdateLayer),
    ]);
}

