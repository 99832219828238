import { createMuiTheme } from '@material-ui/core/styles';
import theme from '../../theme';

export const defaultTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.default,
    },
});

export const accentTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.accent,
    },
});

export const dangerTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.error,
    },
});

export const warningTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.warning,
    },
});

export const successTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.success,
    },
});

export const infoTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.info,
    },
});

export const whiteTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.white,
    },
});

export const grayTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.gray,
    },
});

export const lightTheme = createMuiTheme({
    ...theme,
    palette: {
        primary: theme.palette.light,
    },
});
