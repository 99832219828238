export const PREFIX = 'demo/';
export const RANDOM_PALETTE_REQUESTED = `${PREFIX}RANDOM_PALETTE_REQUESTED`;
export const RANDOM_PALETTE_REQUEST_SUCCEEDED = `${PREFIX}RANDOM_PALETTE_REQUEST_SUCCEEDED`;
export const RANDOM_PALETTE_REQUEST_FAILED = `${PREFIX}RANDOM_PALETTE_REQUEST_FAILED`;

export function randomPaletteRequested() {
    return {
        type: RANDOM_PALETTE_REQUESTED,
    };
}

export function randomPaletteRequestSucceeded(palette) {
    return {
        type: RANDOM_PALETTE_REQUEST_SUCCEEDED,
        payload: { palette },
    };
}

export function randomPaletteRequestFailed(errors) {
    return {
        type: RANDOM_PALETTE_REQUEST_FAILED,
        payload: { errors },
    };
}
