import React, {useEffect, useState} from 'react'
import {
    Button,
    Grid, MenuItem, Select,
    withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    getRandomImageUrl,
    getSummarySentences,
} from '../../generateContent/selectors';
import Editor from '../../common/Lottie/Editor';
import { carimus, halloween } from '../../common/Lottie/data';
import Divider from "../../common/Divider";
import { randomImageRequested } from '../../generateContent/actions';
import {
    getEditorLoading,
    getEditorTemplates,
    getSelectedAnimation, getSelectedAnimationData,
    getSelectedLayer
} from '../../common/Lottie/Editor/selectors';
import {animationSelected, layerSelected} from '../../common/Lottie/Editor/actions'


const ContentEditor = ({
    classes,
    summarySentences,
    selectedTemplate,
    onTemplateSelected,
    onLayerSelected,
    loading,
    availableTemplates,
    selectedLayer,
    animationData,
    randomImage,
    onRandomImageRequested,
}) => {

    if(!selectedTemplate?.id){
        onTemplateSelected(availableTemplates[0])
    }

    return (
        <Grid
            container
            item
            direction="column"
            alignItems="center"
            style={{paddingTop: '10vh'}}
            sm={8}
            md={6}
        >
            <Select
                value={selectedTemplate.id}
                onChange={(event) => {
                    const template = availableTemplates.find(
                        (template) => event.target.value === template.id,
                    );
                    onTemplateSelected(template);
                }}
            >
                {availableTemplates.map((template, index) => {
                    return (
                        <MenuItem value={template.id} key={template.id}>
                            {template.name}
                        </MenuItem>
                    );
                })}
            </Select>
            {loading
                ? <div>...loading</div>
                :  <Editor
                    sentences={summarySentences}
                    animationData={animationData}
                    onLayerSelected={onLayerSelected}
                    selectedLayer={selectedLayer}
                    randomImage={randomImage}
                    onRandomImageRequested={onRandomImageRequested}
                />
            }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedTemplate: getSelectedAnimation(state),
    loading: getEditorLoading(state),
    availableTemplates: getEditorTemplates(state),
    selectedLayer: getSelectedLayer(state),
    animationData: getSelectedAnimationData(state),
    summarySentences: getSummarySentences(state),
    randomImage: getRandomImageUrl(state),
});

const mapDispatchToProps = (dispatch) => ({
    onRandomImageRequested: () => dispatch(randomImageRequested()),
    onTemplateSelected: (template) => dispatch(animationSelected(template)),
    onLayerSelected: (layer) => dispatch(layerSelected(layer))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContentEditor);
